<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <!-- Persona politicamente expuesta -->
            <h5>PERSONA POLÍTICAMENTE EXPUESTA</h5>
            <p>
              Las personas políticamente expuestas son aquellas que desempeñan o han desempeñado
              funciones públicas destacadas en un país extranjero o en su propio país, por ejemplo,
              Jefes de Estados o de un gobierno, políticos de alta jerarquía, funcionarios
              gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de
              empresas estatales, funcionarios importantes de partidos políticos.
            </p>
            <b-row>
              <b-col md="2" cols="12">
                <RadioComponent
                  :form="form"
                  name="exposedPolicy"
                  label="¿Es usted una Persona Políticamente Expuesta?"
                  rules="required"
                  :options="control.TIPOS_SINO"
                  v-model="form.exposedPolicy"
                />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="nameExposedPolicy"
                  label="Primer Nombre"
                  v-model="form.nameExposedPolicy"
                  :disabled="form.exposedPolicy === 'N'"
                  :rules="nameExposedPolicyRules"
                />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="titleExposedPolicy"
                  label="Cargo Actual"
                  v-model="form.titleExposedPolicy"
                  :disabled="form.exposedPolicy === 'N'"
                  :rules="nameExposedPolicyRules"
                />
              </b-col>
            </b-row>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import RadioComponent from "@/components/controls/RadioComponent.vue";
import TextComponent from "@/components/controls/TextComponent.vue";
import { TIPOS_SINO } from "../../../data/panama/Selects";
import { saltarBeneficiarios, saltarDependientes } from "../../../helpers/reglasPanama";

export default {
  name: "Home",
  components: { RadioComponent, TextComponent },
  data: function () {
    return {
      messagge: "",
      control: { LanguageDisabled: 1, TIPOS_SINO },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    if (form === undefined || form === null || form === "") {
      this.$router.push("/");
    }
    this.form = form;
    this.$store.dispatch("app/loading", false);
  },
  computed: {
    nameExposedPolicyRules() {
      return this.form.exposedPolicy === "S" ? "required" : "";
    },
  },
  methods: {
    previous() {
      const form = { ...this.form };

      const saltar_beneficiarios = saltarBeneficiarios(this.form.observations);

      if (saltar_beneficiarios === true)
        this.$store
          .dispatch("wizard/save", { index: 1, form })
          .then(this.$router.push("/form/panama-1.html"));
      else
        this.$store
          .dispatch("wizard/save", { index: 1, form })
          .then(this.$router.push("/form/panama-2.html"));
    },
    next() {
      const form = { ...this.form };

      if (form.exposedPolicy === "NO") {
        form.nameExposedPolicy = "";
        form.ocupacity = "";
      }

      const saltar_dependientes = saltarDependientes(this.form.observations);

      if (saltar_dependientes === true)
        this.$store
          .dispatch("wizard/save", { index: 4, form })
          .then(this.$router.push("/form/panama-5.html"));
      else
        this.$store
          .dispatch("wizard/save", { index: 3, form })
          .then(this.$router.push("/form/panama-4.html"));
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}

.td-button {
  width: 80px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
</style>

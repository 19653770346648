<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">BENEFICIARIOS</h6>
            </template>
            <h5>
              Aplica para la cobertura de vida, identifique la cantidad del porcentaje hasta un
              máximo de 100%
            </h5>
            <table class="table-one">
              <tr>
                <th>Nombre Beneficiario(s)</th>
                <th>Apellido Beneficiario(s)</th>
                <th>%</th>
                <th>Parentesco</th>
                <th>Nro. Identificación</th>
                <th>Edad</th>
                <th style="width: 70px"></th>
              </tr>
              <tr v-for="(item, index) in form.beneficiarios" v-bind:key="index">
                <td style="width: 20%">
                  <b-form-input
                    type="text"
                    v-model="item.name"
                    :state="getValidationName(index)"
                  ></b-form-input>
                </td>

                <td style="width: 20%">
                  <b-form-input
                    type="text"
                    v-model="item.lastName"
                    :state="getValidationLastName(index)"
                  ></b-form-input>
                </td>
                <td style="width: 7%">
                  <validation-provider name="label">
                    <b-form-input
                      type="number"
                      min="0"
                      max="100"
                      v-model="item.percentage"
                      :state="getValidationPercentage(index)"
                    ></b-form-input>
                  </validation-provider>
                </td>
                <td style="width: 20%">
                  <b-form-select
                    v-model="item.relation"
                    :options="control.TIPOS_BENIFICARIOS"
                    :state="getValidationRelaction(index)"
                  ></b-form-select>
                </td>
                <td style="width: 20%">
                  <b-form-input
                    type="text"
                    v-model="item.document"
                    :state="getValidationDocument(index)"
                  ></b-form-input>
                </td>

                <td style="width: 10%">
                  <validation-provider name="label">
                    <b-form-input
                      type="number"
                      v-model="item.age"
                      :state="getValidationAge(index)"
                    ></b-form-input>
                  </validation-provider>
                </td>
                <td style="width: 5%">
                  <b-btn
                    v-if="!rowEmpty(index)"
                    pill
                    variant="outline-danger"
                    @click="limpiar(index)"
                    >Borrar</b-btn
                  >
                </td>
              </tr>
            </table>

            <table class="table-one">
              <tr>
                <th>Nombre Beneficiario(s) Suplentes(s)</th>
                <th>Apellido Beneficiario(s) Suplentes(s)</th>
                <th>%</th>
                <th>Parentesco</th>
                <th>Nro. Identificación</th>
                <th>Edad</th>
                <th style="width: 70px"></th>
              </tr>
              <tr v-for="(item, index) in form.suplentes" v-bind:key="index">
                <td style="width: 20%">
                  <b-form-input
                    type="text"
                    v-model="item.name"
                    :state="getValidationNameSubstitute(index)"
                  ></b-form-input>
                </td>
                <td style="width: 20%">
                  <b-form-input
                    type="text"
                    v-model="item.lastName"
                    :state="getValidationLastNameSubstitute(index)"
                  ></b-form-input>
                </td>
                <td style="width: 7%">
                  <validation-provider name="label">
                    <b-form-input
                      type="number"
                      min="0"
                      max="100"
                      v-model="item.percentage"
                      :state="getValidationPercentageSubstitute(index)"
                    ></b-form-input>
                  </validation-provider>
                </td>
                <td style="width: 20%">
                  <b-form-select
                    v-model="item.relation"
                    :options="control.TIPOS_BENIFICARIOS"
                    :state="getValidationRelactionSubstitute(index)"
                  ></b-form-select>
                </td>

                <td style="width: 20%">
                  <b-form-input
                    type="text"
                    maxlength="11"
                    v-model="item.document"
                    :state="getValidationDocumentSubstitute(index)"
                  ></b-form-input>
                </td>

                <td style="width: 10%">
                  <b-form-input
                    type="number"
                    v-model="item.age"
                    :state="getValidationAgeSubstitute(index)"
                  ></b-form-input>
                </td>
                <td style="width: 7%">
                  <b-btn
                    v-if="!rowEmptySubstitute(index)"
                    pill
                    variant="outline-danger"
                    @click="limpiarSubstitute(index)"
                    >Borrar</b-btn
                  >
                </td>
              </tr>
            </table>

            <p stlye="padding-top:1rem">
              <b>* Advertencia:</b> En el caso de que se desee nombrar beneficiarios a menores de
              edad, no se debe señalar a un mayor de edad como representante de los menores para
              efecto de que, en su representación, cobre la indemnización. Lo anterior porque las
              legislaciones civiles previenen la forma en que debe designarse tutores, albaceas,
              representantes de herederos u otros cargos similares y no consideraran al contrato de
              seguro como el instrumento adecuado para tales designaciones. La designación que se
              hiciera de un mayor de edad como representante de menores beneficiarios, durante la
              minoría de edad de ellos, legalmente puede implicar que se nombra beneficiarios al
              mayor de edad, quien en todo caso sólo tendría una obligación moral, pues la
              designación que se hace de beneficiarios en el contrato de seguro le concede el
              derecho incondicionado de disponer de la suma asegurada.
            </p>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button @click="previous" variant="secondary">Anterior</b-button>
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import { TIPOS_BENIFICARIOS, TIPOS_IDENTIFICACIONES } from "../../../data/panama/Selects";
const edadMaxima = 99;
const edadMinimaConyuge = 18;
const edadMaximaHijo = 99;

export default {
  name: "Home",
  data: function () {
    return {
      messagge: "",
      control: { LanguageDisabled: 1, TIPOS_IDENTIFICACIONES, TIPOS_BENIFICARIOS },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);
    const { form } = this.$store.state.wizard;
    if (form === undefined || form === null || form === "") {
      this.$router.push("/");
    }
    this.form = form;
    this.$store.dispatch("app/loading", false);
  },
  computed: {
    nameExposedPolicyRules() {
      return this.form.exposedPolicy === "S" ? "required" : "";
    },
  },
  methods: {
    previous() {
      const form = { ...this.form };

      this.$store
        .dispatch("wizard/save", { index: 1, form })
        .then(this.$router.push("/form/panama-1.html"));
    },
    next() {
      const form = { ...this.form };

      if (this.tableEmpty()) {
        this.$bvToast.toast("Por favor cargue al menos un beneficiario", {
          title: "Información incompleta",
          variant: "danger",
          solid: true,
        });
        return false;
      }

      if (this.validation() && this.validationSubstitute()) {
        this.$store
          .dispatch("wizard/save", { index: 2, form })
          .then(this.$router.push("/form/panama-3.html"));
      } else {
        this.$bvToast.toast("Por favor controle la información cargada en beneficiarios ", {
          title: "Información incompleta",
          variant: "danger",
          solid: true,
        });
      }
    },
    validation() {
      let ok = true;
      for (let i = 0; i < this.form.beneficiarios.length; i++) {
        if (!this.rowEmpty(i))
          ok =
            ok &&
            this.getValidationName(i) &&
            this.getValidationLastName(i) &&
            this.getValidationPercentage(i) &&
            this.getValidationRelaction(i) &&
            this.getValidationDocument(i) &&
            this.getValidationAge(i);
      }
      return ok;
    },
    validationSubstitute() {
      let ok = true;
      for (let i = 0; i < this.form.suplentes.length; i++) {
        if (!this.rowEmptySubstitute(i))
          ok =
            ok &&
            this.getValidationNameSubstitute(i) &&
            this.getValidationLastNameSubstitute(i) &&
            this.getValidationPercentageSubstitute(i) &&
            this.getValidationRelactionSubstitute(i) &&
            this.getValidationDocumentSubstitute(i) &&
            this.getValidationAgeSubstitute(i);
      }
      return ok;
    },
    tableEmpty() {
      for (let i = 0; i < this.form.beneficiarios.length; i++) {
        if (!this.rowEmpty(i)) {
          return false;
        }
      }
      return true;
    },
    rowEmpty(index) {
      return (
        this.form.beneficiarios[index].name == "" &&
        this.form.beneficiarios[index].lastName == "" &&
        this.form.beneficiarios[index].percentage == "" &&
        this.form.beneficiarios[index].relation == "" &&
        this.form.beneficiarios[index].document == "" &&
        this.form.beneficiarios[index].age == ""
      );
    },
    limpiar(index) {
      this.form.beneficiarios[index].name = "";
      this.form.beneficiarios[index].lastName = "";
      this.form.beneficiarios[index].percentage = "";
      this.form.beneficiarios[index].relation = "";
      this.form.beneficiarios[index].document = "";
      this.form.beneficiarios[index].age = "";
    },
    getValidationName(index) {
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].name != "";

      if (state && this.form.beneficiarios[index].name == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationLastName(index) {
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].lastName != "";

      if (state && this.form.beneficiarios[index].lastName == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationPercentage(index) {
      const valor = Number(this.form.beneficiarios[index].percentage);
      const total = this.form.beneficiarios.reduce(
        (total, current) => total + Number(current.percentage),
        0
      );

      const state = this.rowEmpty(index) || (valor >= 1 && valor <= 100);

      if (state && this.form.beneficiarios[index].percentage == "") {
        return null;
      } else {
        return state && total == 100;
      }
    },
    getValidationRelaction(index) {
      const state =
        this.rowEmpty(index) ||
        TIPOS_BENIFICARIOS.map((e) => e.value).includes(this.form.beneficiarios[index].relation);

      if (state && this.form.beneficiarios[index].relation == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationDocument(index) {
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].document != "";

      if (state && this.form.beneficiarios[index].document == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationAge(index) {
      const valor = Number(this.form.beneficiarios[index].age);
      const state = this.rowEmpty(index) || this.form.beneficiarios[index].age != "";

      if (state && this.form.beneficiarios[index].age == "") {
        return null;
      } else {
        if (this.form.beneficiarios[index].relation == "C") {
          return state && valor >= edadMinimaConyuge && valor <= edadMaxima;
        }
        if (this.form.beneficiarios[index].relation == "H") {
          return state && valor > 0 && valor <= edadMaximaHijo;
        }
        return state && valor > 0 && valor < edadMaxima;
      }
    },

    /* SUPLENTES */

    rowEmptySubstitute(index) {
      return (
        this.form.suplentes[index].name == "" &&
        this.form.suplentes[index].lastName == "" &&
        this.form.suplentes[index].percentage == "" &&
        this.form.suplentes[index].relation == "" &&
        this.form.suplentes[index].document == "" &&
        this.form.suplentes[index].age == ""
      );
    },
    limpiarSubstitute(index) {
      this.form.suplentes[index].name = "";
      this.form.suplentes[index].lastName = "";
      this.form.suplentes[index].percentage = "";
      this.form.suplentes[index].relation = "";
      this.form.suplentes[index].document = "";
      this.form.suplentes[index].age = "";
    },

    getValidationNameSubstitute(index) {
      const state = this.rowEmptySubstitute(index) || this.form.suplentes[index].name != "";

      if (state && this.form.suplentes[index].name == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationLastNameSubstitute(index) {
      const state = this.rowEmptySubstitute(index) || this.form.suplentes[index].lastName != "";

      if (state && this.form.suplentes[index].lastName == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationPercentageSubstitute(index) {
      const valor = Number(this.form.suplentes[index].percentage);
      const total = this.form.suplentes.reduce(
        (total, current) => total + Number(current.percentage),
        0
      );

      const state = this.rowEmptySubstitute(index) || (valor >= 1 && valor <= 100);

      if (state && this.form.suplentes[index].percentage == "") {
        return null;
      } else {
        return state && total == 100;
      }
    },
    getValidationRelactionSubstitute(index) {
      const state =
        this.rowEmptySubstitute(index) ||
        TIPOS_BENIFICARIOS.map((e) => e.value).includes(this.form.suplentes[index].relation);

      if (state && this.form.suplentes[index].relation == "") {
        return null;
      } else {
        return state;
      }
    },

    getValidationDocumentSubstitute(index) {
      const state = this.rowEmptySubstitute(index) || this.form.suplentes[index].document != "";

      if (state && this.form.suplentes[index].document == "") {
        return null;
      } else {
        return state;
      }
    },
    getValidationAgeSubstitute(index) {
      const valor = Number(this.form.suplentes[index].age);
      const state = this.rowEmptySubstitute(index) || this.form.suplentes[index].age != "";

      if (state && this.form.suplentes[index].age == "") {
        return null;
      } else {
        if (this.form.suplentes[index].relation == "CY") {
          return state && valor >= edadMinimaConyuge && valor <= edadMaxima;
        }
        if (this.form.suplentes[index].relation == "HJ") {
          return state && valor > 0 && valor <= edadMaximaHijo;
        }
        return state && valor > 0 && valor <= edadMaxima;
      }
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}

.td-button {
  width: 80px;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
}
</style>
